/* COLORS
---------------------- */
$color-blue: #004990;

$color-green: #609240;
$color-green2: #4f7835;

$color-white: #fff;

$color-gray: #808080;
$color-gray2: #d0d3d4;
$color-gray3: #99a4a8;
$color-gray3: #999;


/* font family
----------------------  */
$font-family: 'Open Sans', Tahoma, Arial, Verdana, Georgia, 'Times New Roman', Times, serif;
$font-family2: verdana, geneva;;


/* Mainmenu
---------------------- */
$mainmenu-bck: $color-blue;
$mainmenu-item-height: 40px;
$mainmenu-item-bck-hover: $color-green;
$mainmenu-item-bck-active: $color-green;
$mainmenu-item-color: $color-white;
$mainmenu-item-font-size: 14px;
$mainmenu-item-sub-bck: $color-green2;


/* breadcrumb
---------------------- */
$breadcrumb-color: $color-gray;


/* Content
---------------------- */
$content-header-color: $color-green;


/* Footer
---------------------- */
$footer-bck: $color-gray2;
$footer-color: $color-gray3;


/* Blog
---------------------- */
$blog-header-color: $color-blue;
$blog-border-color: $color-gray3;

/* Main Blog Columns
---------------------- */
$blogmain-header-color: $color-green;
$blogmain-content-date-color: $color-gray3;
$blogmain-content-header-color: $color-blue;
$blogmain-content-border-color: $color-gray3;


/* Responsive
---------------------- */
$xs: 768px;   /* < 768px */
$sm: 768px;   /* ≥ 768px */
$md: 992px;   /* ≥ 992px */
$lg: 1200px;  /* ≥1200px */



$transition-time: 0.3s;