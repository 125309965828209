.bx-wrapper {

  img {
    max-width: 100%;
    min-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
  }

  .bx-viewport {
    left: 0;
  }

  .bx-caption {
    span {
      color: #fff;
      font-family: Arial;
      display: block;
      font-size: 16px;
      padding: 20px 20px 30px 20px;
    }
  }

}
