div.moduletableagromap {
  position: relative;
  margin: 0;
  padding: 0;

  .ukrmapa {
    position: relative;
    margin: 0;
    padding: 0;
    width: 960px;
    background: url(/images/maps/ukr_map.png) 0px 0px no-repeat;

    .onlymap {
      position: relative;
      margin: 0;
      padding: 0;
      z-index: 11;
    }

    #uzhgorod {
      display: none;
      position: absolute;
      z-index: 9;
      margin: 0;
      padding: 0;
      left: 0;
      top: 232px;
    }
    #frankivsk {
      display: none;
      position: absolute;
      z-index: 9;
      margin: 0;
      padding: 0;
      left: 78px;
      top: 208px;
    }
    #chernivtsi {
      display: none;
      position: absolute;
      z-index: 9;
      margin: 0;
      padding: 0;
      left: 144px;
      top: 283px;
    }
    #lviv {
      display: none;
      position: absolute;
      z-index: 9;
      margin: 0;
      padding: 0;
      left: 37px;
      top: 109px;
    }
    #lutsk {
      display: none;
      position: absolute;
      z-index: 9;
      margin: 0;
      padding: 0;
      left: 107px;
      top: 15px;
    }
    #rivne {
      display: none;
      position: absolute;
      z-index: 9;
      margin: 0;
      padding: 0;
      left: 174px;
      top: 19px;
    }
    #ternopol {
      display: none;
      position: absolute;
      z-index: 9;
      margin: 0;
      padding: 0;
      left: 147px;
      top: 156px;
    }
    #khmelnitsky {
      display: none;
      position: absolute;
      z-index: 9;
      margin: 0;
      padding: 0;
      left: 220px;
      top: 135px;
    }
    #zhitomir {
      display: none;
      position: absolute;
      z-index: 9;
      margin: 0;
      padding: 0;
      left: 281px;
      top: 52px;
    }
    #vinnytsya {
      display: none;
      position: absolute;
      z-index: 9;
      margin: 0;
      padding: 0;
      left: 283px;
      top: 196px;
    }
    #kyiv {
      display: none;
      position: absolute;
      z-index: 9;
      margin: 0;
      padding: 0;
      left: 390px;
      top: 65px;
    }
    #cherkasy {
      display: none;
      position: absolute;
      z-index: 9;
      margin: 0;
      padding: 0;
      left: 401px;
      top: 173px;
    }
    #kirovograd {
      display: none;
      position: absolute;
      z-index: 9;
      margin: 0;
      padding: 0;
      left: 406px;
      top: 264px;
    }
    #nikolayev {
      display: none;
      position: absolute;
      z-index: 9;
      margin: 0;
      padding: 0;
      left: 431px;
      top: 333px;
    }
    #odessa {
      display: none;
      position: absolute;
      z-index: 9;
      margin: 0;
      padding: 0;
      left: 315px;
      top: 331px;
    }
    #krym {
      display: none;
      position: absolute;
      z-index: 9;
      margin: 0;
      padding: 0;
      right: 170px;
      top: 494px;
    }
    #kherson {
      display: none;
      position: absolute;
      z-index: 9;
      margin: 0;
      padding: 0;
      right: 250px;
      top: 385px;
    }
    #zapor {
      display: none;
      position: absolute;
      z-index: 9;
      margin: 0;
      padding: 0;
      right: 147px;
      top: 338px;
    }
    #dnipro {
      display: none;
      position: absolute;
      z-index: 9;
      margin: 0;
      padding: 0;
      right: 166px;
      top: 255px;
    }
    #donetsk {
      display: none;
      position: absolute;
      z-index: 9;
      margin: 0;
      padding: 0;
      right: 46px;
      top: 242px;
    }
    #poltava {
      display: none;
      position: absolute;
      z-index: 9;
      margin: 0;
      padding: 0;
      right: 249px;
      top: 146px;
    }
    #kharkiv {
      display: none;
      position: absolute;
      z-index: 9;
      margin: 0;
      padding: 0;
      right: 113px;
      top: 147px;
    }
    #chernigov {
      display: none;
      position: absolute;
      z-index: 9;
      margin: 0;
      padding: 0;
      left: 456px;
      top: 0px;
    }
    #sumy {
      display: none;
      position: absolute;
      z-index: 9;
      margin: 0;
      padding: 0;
      left: 577px;
      top: 1px;
    }
    #lugansk {
      display: none;
      position: absolute;
      z-index: 9;
      margin: 0;
      padding: 0;
      right: 0;
      top: 173px;
    }

    .point {
      position: absolute;
      z-index: 12;
      cursor: pointer;
      margin: 0;
      padding: 0;
      text-align: center;

      div.leg1 {
        margin: 0 auto;
        padding: 0;
        width: 28px;
        height: 25px;
        overflow: hidden;
        cursor: pointer;
        background: url(/images/maps/legenda01.png) 0px 0px no-repeat;
      }
      div.leg1.hover {
        background: url(/images/maps/legenda01.png) 0px -25px no-repeat;
      }
      div.leg2 {
        margin: 0 auto;
        padding: 0;
        width: 28px;
        height: 25px;
        overflow: hidden;
        cursor: pointer;
        background: url(/images/maps/legenda02.png) 0px 0px no-repeat;
      }
      div.leg2.hover {
        background: url(/images/maps/legenda02.png) 0px -25px no-repeat;
      }

      p {
        margin: -5px 0px 0px 0px;
        padding: 0;
        font-size: 12px;
        color: #004990;
      }
      p.hover {
        color: #0067cc;
      }
    }

    div.ocity {
      font-size: 12px;
      color: #000;
      position: absolute;
      z-index: 11;
      margin: 0;
      padding: 0;
    }

  }

  #kha {
    left: 720px;
    top: 190px;
  }
  #don {
    left: 823px;
    top: 325px;
  }
  #lug {
    left: 910px;
    top: 270px;
  }
  #dni {
    left: 637px;
    top: 287px;
  }
  #sim {
    left: 620px;
    top: 575px;
  }
  #khe {
    left: 550px;
    top: 458px;
  }
  #zap {
    left: 680px;
    top: 363px;
  }
  #myk {
    left: 490px;
    top: 416px;
  }
  #ode {
    left: 440px;
    top: 440px;
  }
  #kir {
    left: 506px;
    top: 315px;
  }
  #pol {
    left: 640px;
    top: 223px;
  }
  #sum {
    left: 655px;
    top: 112px;
  }
  #cgv {
    left: 475px;
    top: 73px;
  }
  #kyi {
    left: 440px;
    top: 155px;
  }
  #zhy {
    left: 330px;
    top: 145px;
  }
  #riv {
    left: 225px;
    top: 113px;
  }
  #lut {
    left: 162px;
    top: 117px;
  }
  #ter {
    left: 162px;
    top: 213px;
  }
  #lvi {
    left: 92px;
    top: 187px;
  }
  #fra {
    left: 77px;
    top: 260px;
  }
  #cnc {
    left: 175px;
    top: 314px;
  }
  #uzh {
    left: 0px;
    top: 267px;
  }

  #chapayeve {
    left: 670px;
    top: 180px;
  }
  #vasyschyve {
    left: 750px;
    top: 160px;
  }
  #lozova {
    left: 740px;
    top: 260px;
  }
  #pokrovske {
    left: 725px;
    top: 325px;
  }
  #troyany {
    left: 755px;
    top: 410px;
  }
  #vesele {
    left: 665px;
    top: 405px;
  }
  #snigurivka {
    left: 536px;
    top: 408px;
  }
  #vilnogirsk {
    left: 596px;
    top: 320px;
  }
  #dolynska {
    left: 514px;
    top: 340px;
  }
  #novomyrgorod {
    left: 500px;
    top: 270px;
  }
  #balta {
    left: 375px;
    top: 343px;
  }
  #lyubashivka {
    left: 390px;
    top: 370px;
  }
  #nizhyn {
    left: 515px;
    top: 90px;
  }
  #cherni {
    left: 480px;
    top: 47px;
  }
  #romny {
    left: 596px;
    top: 108px;
  }
  #baryshivka {
    left: 475px;
    top: 148px;
  }
  #pyryatyn {
    left: 553px;
    top: 170px;
  }
  #vyly {
    left: 595px;
    top: 200px;
  }
  #cherkassy {
    left: 500px;
    top: 218px;
  }
  #zhashkiv {
    left: 400px;
    top: 243px;
  }
  #fastiv {
    left: 410px;
    top: 186px;
  }
  #andrushivka {
    left: 325px;
    top: 181px;
  }
  #vinnytsya2 {
    left: 320px;
    top: 234px;
  }
  #zhmerynka {
    left: 285px;
    top: 270px;
  }
  #starokostyantyniv {
    left: 222px;
    top: 170px;
  }
  #khmelnitsky2 {
    left: 220px;
    top: 215px;
  }
  #torske {
    left: 180px;
    top: 260px;
  }
  #kivertsi {
    left: 185px;
    top: 69px;
  }
  #brody {
    left: 135px;
    top: 150px;
  }

  div.adre {
    display: none;
    position: absolute;
    margin: 0;
    padding: 0;
    width: 310px;
    left: 0px;
    top: 410px;
    z-index: 13;
    background: #e5e5e5;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -khtml-border-radius: 5px;
    border-radius: 5px;
    behavior: url(/PIE.htc);
  }
  div.adre.t1 {
    background: url(/images/t1.gif) 10px 10px no-repeat #e5e5e5;
  }
  div.adre.t2 {
    background: url(../images/t2.gif) 10px 10px no-repeat #e5e5e5;
  }
  div.adad {
    position: relative;
    margin: 0;
    padding: 10px 20px 10px 40px;
  }
  #des1 {
    position: absolute;
    margin: 0;
    padding: 0px 0px 0px 30px;
    left: 800px;
    top: 470px;
    z-index: 13;
    background: url(../images/t1.gif) 0px 0px no-repeat;
  }
  #des2 {
    position: absolute;
    margin: 0;
    padding: 0px 0px 0px 30px;
    left: 800px;
    top: 520px;
    z-index: 13;
    background: url(../images/t2.gif) 0px 0px no-repeat;
  }
}