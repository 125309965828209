
.nav.navbar-nav.left-menu{

  background-color: #d0d3d4;
  padding: 0;

  li {
    padding: 0;
    margin: 0;
    width: 100%;
    border-top: 1px dotted #fff;
    color: #fff;

    &.active {
      background: #4f7835;
        a {
          color: #fff;
        }
    }

      a{
        padding: 10px 15px;
        margin: 0;
        color: #000;
        display: block;
        font-size: 14px;
        text-decoration: none;
        line-height: 1.5;
        transition: $transition-time;

        &:hover{
          background: #4f7835;
          color: #fff;
        }

      }


  }
   
}