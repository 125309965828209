.item-page {
  font-family: $font-family2;
  font-size: 14px;

  .page-header {
    padding-bottom: 0px;
    margin: 10px 0 20px;
    border-bottom: 1px solid $content-header-color;

    h2 {
      font-size: 16px;
      color: $content-header-color;
      text-transform: uppercase;
      margin-bottom: 5px;
    }
  }

  .article-info{
    &.muted{
      .article-info-term{
        display: none;
      }
      .create{
        font-size: 16px;
      }
    }
  }

  h4 {
    text-indent: 20px;
    margin: 25px 0 15px 0;
    font-size: 16px;
    font-weight: bold;
  }

  p {
    text-indent: 20px;
    margin: 0 0 15px;
  }

  img {
    &.img-left {
      margin-right: 20px;
    }
  }
}

.blog {
  .items-leading {
    & > div {
      border-bottom: 1px dotted $blog-border-color;
      h4 {
        font-size: 14px;
        font-weight: bold;
        margin: 0;
        padding: 10px 0;
      }
    }
  }

  .article-info.muted{
    .article-info-term{
      display: none;
    }

    .create{
      color: $blogmain-content-date-color;
      font-size: 14px;
    }

  }


  .page-header {
    border: none;
    margin: 0;

    h2 {
      margin: 0;
      padding: 5px 0;
      font-size: 21px;


      a {
        color: $blog-header-color;
        text-decoration: none;
        font-size: 20px;
        font-weight: bold;
      }
    }
  }

  p {
    &.readmore {
      margin: 0;

      a {
        padding: 6px 0 15px 0;
      }
    }
  }

  /*  -----  */
  .items-row {
    &:after {
      content: ' ';
      display: block;
      clear: both;
      height: 1px;
    }

    &.cols-2 {
      .span6 {
        display: block;
        width: 50%;
        float: left;
        padding: 0 5px;
      }
    }

    .item {
      height: 255px;
      overflow: hidden;
      position: relative;
      border-bottom: 1px dotted $color-gray3;
      padding: 5px 5px;

      &:after{
        content: ' ';
        display: block;
        clear: both;
      }

      .page-header {
        border: none;
        margin: 0;

        h2 {
          margin: 10px 0 0 0;
          padding: 0;
          line-height: 0.7;
          height: 45px;
          overflow: hidden;

          a {
            color: $blog-header-color;
            text-decoration: none;
            font-size: 20px;
            font-weight: bold;
          }
        }
      }

      h4 {
        font-size: 14px;
        font-weight: bold;
        margin: 0;
        padding: 0;
        overflow: hidden;
        height: 35px;
        line-height: 1.2;

      }

      p{
        &.readmore{
          height: 35px;
          width: 100%;
          background: #fff;
          position: absolute;
          bottom: 0;
          left: 0;
          text-transform: lowercase;
          text-align: right;
        }
      }

    }

    &.cols-1{
      .item.column-1{
        height: auto;
        min-height: 255px;

        .page-header {
          h2{
            height: auto;
            padding: 5px 0;
          }
        }
      }
    }

  }

}

.img-container{
  text-align: center;
  img{
    width: 30%;
    height: auto;
  }
}

.img-big-container{
  text-align: center;
  img{
    width: 90%;
    height: auto;
    margin: 10px;
  }

}