@media screen and (max-width: $xs) {
  nav.navbar-default {
    margin: 0;

    .container-fluid {
      padding-right: 15px;
      padding-left: 15px;

      .navbar-nav {
        & > li {

          &.dropdown {
            &.open {
              .dropdown-menu {
                li{
                  a{
                    color: $mainmenu-item-color;
                  }
                }
              }
            }
          }

        }
      }
    }

  }


  /* CONTENT
  ------------------------- */
  .blog {

  /*  -----  */
  .items-row {

    &.cols-2 {
      .span6 {
        width: 100%;
      }
    }

    .item {
    }

  }

}



  /* FOOTER
  ------------------------- */

  footer.footer {
    position: relative;
    bottom: 0;

    margin: 20px -15px 0 -15px;
  }

}