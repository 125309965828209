@mixin menu-color-bck{
  color: $mainmenu-item-color;
  background: $mainmenu-item-bck-active;
}

.navbar-default {
  background: $mainmenu-bck;
  border-radius: 0;
  border: none;
  //height: $mainmenu-item-height;
  min-height: $mainmenu-item-height;
  z-index: 999;

  .container-fluid {
    padding-left: 0;

    .navbar-collapse, .navbar-form {
      padding-left: 0;
      border: none;
    }

    .navbar-nav {
      & > li {
        &.default {
          a {
            font-size: 1px;
            position: relative;
            top: 0px;
            display: inline-block;
            font-family: 'Glyphicons Halflings';
            font-style: normal;
            font-weight: 400;
            -webkit-font-smoothing: antialiased;

            &:before {
              font-size: 20px;
              content: "\e021";
            }
          }
        }

        & > a {
          height: $mainmenu-item-height;
          padding: 0 20px;
          margin: 0;
          display: block;
          color: $mainmenu-item-color;
          line-height: $mainmenu-item-height;
          text-transform: uppercase;
          font-size: $mainmenu-item-font-size;
          transition: $transition-time;

          &:hover {
            @include menu-color-bck;
          }
        }

        .dropdown-menu{
          @include menu-color-bck;
          border: none;
          border-radius: 0;
          transition: $transition-time;
          opacity: 0;

          li{
            border-bottom: 1px dotted $color-white;

            a{
              transition: $transition-time;
              font-size: $mainmenu-item-font-size;
              padding-top: 7px;
              padding-bottom: 7px;
              &:hover{
                background: $mainmenu-item-sub-bck;
              }
            }

            &:last-of-type{
              border-bottom: none;
            }

            &.active{
              a{
                background: $mainmenu-item-sub-bck;
              }
            }
          }
        }

        &.dropdown{
          &.open{
            .dropdown-menu{
              opacity: 1;
            }
          }
        }
      }

      & > .active {
        & > a {
          @include menu-color-bck;

          &:focus, &:hover {
            @include menu-color-bck;
          }
        }
      }

      & > .open{
        a{
          @include menu-color-bck;
          &:focus, &:hover {
            @include menu-color-bck;
          }
        }
      }
    }
  }
}
