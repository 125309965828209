@import "variable";
@import "mainmenu";
@import "breadcrumb";
@import "content";
@import "footer";
@import "responsive_xs";
@import "leftmenu";
@import "phoca";
@import "ukrmap";

html {
  position: relative;
  min-height: 100%;
}

body {
  height: 100%;
  font-family: $font-family;
  font-size: 12px;
  color: #000;
  text-align: left;
  margin-bottom: 250px;
}

header {
  margin: 20px 0;
}

#scrollup {
  position: fixed;
  width: 45px;
  height: 45px;
  bottom: 50px;
  left: 10px;
  opacity: 0;

  text-align: center;
  font-size: 27px;
  padding: 0 3px 0 0;
  text-indent: 0;

  background: blue;
  border-radius: 100%;
  border: 5px solid green;
  color: white;

  transition: $transition-time;
  pointer-events: none;

  &.active {
    display: block;
    opacity: 0.5;
    pointer-events: auto;

    &:hover {
      opacity: 1;
    }
  }
}

/* blog columns
------------------------ */
.beforebody {
  display: flex;

  & > div {
    border-right: 1px solid $blogmain-content-border-color;
    &:nth-child(3){
      border: none;
    }

    .main-blog-col {

      & > h3 {
        color: $blogmain-header-color;
        font-size: 18px;
        padding: 0 0 10px;
        text-transform: uppercase;
        text-align: left;
        font-weight: normal;
      }

      ul {
        list-style-type: none;
        margin: 0;
        padding: 0;

        li{
          border-bottom: 1px dotted $color-gray3;
          margin-bottom: 10px;
        }
      }

      .data_content {
        color: $blogmain-content-date-color;
        font-size: 10px;
      }

      h4.newsflash-title.main-blog-col {
        margin: 0;
        padding: 2px 0 5px 0;

        a {
          color: $blogmain-content-header-color;
          text-decoration: none;
          font-size: 12px;
        }

      }

      .newsflash-vert{
        h1, h2, h3, h4, h5{
          &:not(.newsflash-title){
            font-size: 12px !important;
            text-align: left !important;
            padding: 1px 0 !important;
            margin: 1px 0 !important;
            font-weight: normal !important;
            color: #000;
          }
        }
      }


    }
  }
}
