.breadcrumb {
  padding: 8px 0px;
  margin: 0;
  list-style: none;
  background: none;
  border-radius: 0;
  text-align: left;

  & > li {
    color: $breadcrumb-color;

    a{
      color: $breadcrumb-color;
      text-decoration: underline;
    }
  }

  & > li + li:before {
    display: none;
  }
}
