footer {
  display: block;

  &.footer {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    min-height: 200px;
    background: none;
    color: $footer-color;

    .before-footer {
      background: $footer-bck;
      padding: 10px 20px;
      position: relative;

      .moduletable {
        .search {
          text-align: right;

          form {
            label {
              font-weight: normal;
            }

            #mod-search-searchword88 {
              background: transparent;
              border: 1px solid #fff;
              color: #fff;
              font-size: 12px;
              height: 20px;
              margin-right: 5px;
              padding: 0 2px;
              vertical-align: middle;
              width: 140px;
            }

            input[type=image] {
              vertical-align: middle;
            }

          }
        }
      }
    }

    .for-modules {

      h3 {
        border: none;
        font-size: 14px;
        color: #99a4a8;
        font-weight: 700;
        padding: 0 0 3px;
        text-transform: uppercase;
      }

      .phone {
        color: #1f1a15;
        font-size: 20px;
        font-weight: 700;
      }
    }

  }

}