@charset "UTF-8";
/* COLORS
---------------------- */
/* font family
----------------------  */
/* Mainmenu
---------------------- */
/* breadcrumb
---------------------- */
/* Content
---------------------- */
/* Footer
---------------------- */
/* Blog
---------------------- */
/* Main Blog Columns
---------------------- */
/* Responsive
---------------------- */
/* < 768px */
/* ≥ 768px */
/* ≥ 992px */
/* ≥1200px */
.navbar-default {
  background: #004990;
  border-radius: 0;
  border: none;
  min-height: 40px;
  z-index: 999; }
  .navbar-default .container-fluid {
    padding-left: 0; }
    .navbar-default .container-fluid .navbar-collapse, .navbar-default .container-fluid .navbar-form {
      padding-left: 0;
      border: none; }
    .navbar-default .container-fluid .navbar-nav > li.default a {
      font-size: 1px;
      position: relative;
      top: 0px;
      display: inline-block;
      font-family: 'Glyphicons Halflings';
      font-style: normal;
      font-weight: 400;
      -webkit-font-smoothing: antialiased; }
      .navbar-default .container-fluid .navbar-nav > li.default a:before {
        font-size: 20px;
        content: "\e021"; }
    .navbar-default .container-fluid .navbar-nav > li > a {
      height: 40px;
      padding: 0 20px;
      margin: 0;
      display: block;
      color: #fff;
      line-height: 40px;
      text-transform: uppercase;
      font-size: 14px;
      transition: 0.3s; }
      .navbar-default .container-fluid .navbar-nav > li > a:hover {
        color: #fff;
        background: #609240; }
    .navbar-default .container-fluid .navbar-nav > li .dropdown-menu {
      color: #fff;
      background: #609240;
      border: none;
      border-radius: 0;
      transition: 0.3s;
      opacity: 0; }
      .navbar-default .container-fluid .navbar-nav > li .dropdown-menu li {
        border-bottom: 1px dotted #fff; }
        .navbar-default .container-fluid .navbar-nav > li .dropdown-menu li a {
          transition: 0.3s;
          font-size: 14px;
          padding-top: 7px;
          padding-bottom: 7px; }
          .navbar-default .container-fluid .navbar-nav > li .dropdown-menu li a:hover {
            background: #4f7835; }
        .navbar-default .container-fluid .navbar-nav > li .dropdown-menu li:last-of-type {
          border-bottom: none; }
        .navbar-default .container-fluid .navbar-nav > li .dropdown-menu li.active a {
          background: #4f7835; }
    .navbar-default .container-fluid .navbar-nav > li.dropdown.open .dropdown-menu {
      opacity: 1; }
    .navbar-default .container-fluid .navbar-nav > .active > a {
      color: #fff;
      background: #609240; }
      .navbar-default .container-fluid .navbar-nav > .active > a:focus, .navbar-default .container-fluid .navbar-nav > .active > a:hover {
        color: #fff;
        background: #609240; }
    .navbar-default .container-fluid .navbar-nav > .open a {
      color: #fff;
      background: #609240; }
      .navbar-default .container-fluid .navbar-nav > .open a:focus, .navbar-default .container-fluid .navbar-nav > .open a:hover {
        color: #fff;
        background: #609240; }

.breadcrumb {
  padding: 8px 0px;
  margin: 0;
  list-style: none;
  background: none;
  border-radius: 0;
  text-align: left; }
  .breadcrumb > li {
    color: #808080; }
    .breadcrumb > li a {
      color: #808080;
      text-decoration: underline; }
  .breadcrumb > li + li:before {
    display: none; }

.item-page {
  font-family: verdana, geneva;
  font-size: 14px; }
  .item-page .page-header {
    padding-bottom: 0px;
    margin: 10px 0 20px;
    border-bottom: 1px solid #609240; }
    .item-page .page-header h2 {
      font-size: 16px;
      color: #609240;
      text-transform: uppercase;
      margin-bottom: 5px; }
  .item-page .article-info.muted .article-info-term {
    display: none; }
  .item-page .article-info.muted .create {
    font-size: 16px; }
  .item-page h4 {
    text-indent: 20px;
    margin: 25px 0 15px 0;
    font-size: 16px;
    font-weight: bold; }
  .item-page p {
    text-indent: 20px;
    margin: 0 0 15px; }
  .item-page img.img-left {
    margin-right: 20px; }

.blog {
  /*  -----  */ }
  .blog .items-leading > div {
    border-bottom: 1px dotted #999; }
    .blog .items-leading > div h4 {
      font-size: 14px;
      font-weight: bold;
      margin: 0;
      padding: 10px 0; }
  .blog .article-info.muted .article-info-term {
    display: none; }
  .blog .article-info.muted .create {
    color: #999;
    font-size: 14px; }
  .blog .page-header {
    border: none;
    margin: 0; }
    .blog .page-header h2 {
      margin: 0;
      padding: 5px 0;
      font-size: 21px; }
      .blog .page-header h2 a {
        color: #004990;
        text-decoration: none;
        font-size: 20px;
        font-weight: bold; }
  .blog p.readmore {
    margin: 0; }
    .blog p.readmore a {
      padding: 6px 0 15px 0; }
  .blog .items-row:after {
    content: ' ';
    display: block;
    clear: both;
    height: 1px; }
  .blog .items-row.cols-2 .span6 {
    display: block;
    width: 50%;
    float: left;
    padding: 0 5px; }
  .blog .items-row .item {
    height: 255px;
    overflow: hidden;
    position: relative;
    border-bottom: 1px dotted #999;
    padding: 5px 5px; }
    .blog .items-row .item:after {
      content: ' ';
      display: block;
      clear: both; }
    .blog .items-row .item .page-header {
      border: none;
      margin: 0; }
      .blog .items-row .item .page-header h2 {
        margin: 10px 0 0 0;
        padding: 0;
        line-height: 0.7;
        height: 45px;
        overflow: hidden; }
        .blog .items-row .item .page-header h2 a {
          color: #004990;
          text-decoration: none;
          font-size: 20px;
          font-weight: bold; }
    .blog .items-row .item h4 {
      font-size: 14px;
      font-weight: bold;
      margin: 0;
      padding: 0;
      overflow: hidden;
      height: 35px;
      line-height: 1.2; }
    .blog .items-row .item p.readmore {
      height: 35px;
      width: 100%;
      background: #fff;
      position: absolute;
      bottom: 0;
      left: 0;
      text-transform: lowercase;
      text-align: right; }
  .blog .items-row.cols-1 .item.column-1 {
    height: auto;
    min-height: 255px; }
    .blog .items-row.cols-1 .item.column-1 .page-header h2 {
      height: auto;
      padding: 5px 0; }

.img-container {
  text-align: center; }
  .img-container img {
    width: 30%;
    height: auto; }

.img-big-container {
  text-align: center; }
  .img-big-container img {
    width: 90%;
    height: auto;
    margin: 10px; }

footer {
  display: block; }
  footer.footer {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    min-height: 200px;
    background: none;
    color: #999; }
    footer.footer .before-footer {
      background: #d0d3d4;
      padding: 10px 20px;
      position: relative; }
      footer.footer .before-footer .moduletable .search {
        text-align: right; }
        footer.footer .before-footer .moduletable .search form label {
          font-weight: normal; }
        footer.footer .before-footer .moduletable .search form #mod-search-searchword88 {
          background: transparent;
          border: 1px solid #fff;
          color: #fff;
          font-size: 12px;
          height: 20px;
          margin-right: 5px;
          padding: 0 2px;
          vertical-align: middle;
          width: 140px; }
        footer.footer .before-footer .moduletable .search form input[type=image] {
          vertical-align: middle; }
    footer.footer .for-modules h3 {
      border: none;
      font-size: 14px;
      color: #99a4a8;
      font-weight: 700;
      padding: 0 0 3px;
      text-transform: uppercase; }
    footer.footer .for-modules .phone {
      color: #1f1a15;
      font-size: 20px;
      font-weight: 700; }

@media screen and (max-width: 768px) {
  nav.navbar-default {
    margin: 0; }
    nav.navbar-default .container-fluid {
      padding-right: 15px;
      padding-left: 15px; }
      nav.navbar-default .container-fluid .navbar-nav > li.dropdown.open .dropdown-menu li a {
        color: #fff; }
  /* CONTENT
  ------------------------- */
  .blog {
    /*  -----  */ }
    .blog .items-row.cols-2 .span6 {
      width: 100%; }
  /* FOOTER
  ------------------------- */
  footer.footer {
    position: relative;
    bottom: 0;
    margin: 20px -15px 0 -15px; } }

.nav.navbar-nav.left-menu {
  background-color: #d0d3d4;
  padding: 0; }
  .nav.navbar-nav.left-menu li {
    padding: 0;
    margin: 0;
    width: 100%;
    border-top: 1px dotted #fff;
    color: #fff; }
    .nav.navbar-nav.left-menu li.active {
      background: #4f7835; }
      .nav.navbar-nav.left-menu li.active a {
        color: #fff; }
    .nav.navbar-nav.left-menu li a {
      padding: 10px 15px;
      margin: 0;
      color: #000;
      display: block;
      font-size: 14px;
      text-decoration: none;
      line-height: 1.5;
      transition: 0.3s; }
      .nav.navbar-nav.left-menu li a:hover {
        background: #4f7835;
        color: #fff; }

.bx-wrapper img {
  max-width: 100%;
  min-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto; }

.bx-wrapper .bx-viewport {
  left: 0; }

.bx-wrapper .bx-caption span {
  color: #fff;
  font-family: Arial;
  display: block;
  font-size: 16px;
  padding: 20px 20px 30px 20px; }

div.moduletableagromap {
  position: relative;
  margin: 0;
  padding: 0; }
  div.moduletableagromap .ukrmapa {
    position: relative;
    margin: 0;
    padding: 0;
    width: 960px;
    background: url(/images/maps/ukr_map.png) 0px 0px no-repeat; }
    div.moduletableagromap .ukrmapa .onlymap {
      position: relative;
      margin: 0;
      padding: 0;
      z-index: 11; }
    div.moduletableagromap .ukrmapa #uzhgorod {
      display: none;
      position: absolute;
      z-index: 9;
      margin: 0;
      padding: 0;
      left: 0;
      top: 232px; }
    div.moduletableagromap .ukrmapa #frankivsk {
      display: none;
      position: absolute;
      z-index: 9;
      margin: 0;
      padding: 0;
      left: 78px;
      top: 208px; }
    div.moduletableagromap .ukrmapa #chernivtsi {
      display: none;
      position: absolute;
      z-index: 9;
      margin: 0;
      padding: 0;
      left: 144px;
      top: 283px; }
    div.moduletableagromap .ukrmapa #lviv {
      display: none;
      position: absolute;
      z-index: 9;
      margin: 0;
      padding: 0;
      left: 37px;
      top: 109px; }
    div.moduletableagromap .ukrmapa #lutsk {
      display: none;
      position: absolute;
      z-index: 9;
      margin: 0;
      padding: 0;
      left: 107px;
      top: 15px; }
    div.moduletableagromap .ukrmapa #rivne {
      display: none;
      position: absolute;
      z-index: 9;
      margin: 0;
      padding: 0;
      left: 174px;
      top: 19px; }
    div.moduletableagromap .ukrmapa #ternopol {
      display: none;
      position: absolute;
      z-index: 9;
      margin: 0;
      padding: 0;
      left: 147px;
      top: 156px; }
    div.moduletableagromap .ukrmapa #khmelnitsky {
      display: none;
      position: absolute;
      z-index: 9;
      margin: 0;
      padding: 0;
      left: 220px;
      top: 135px; }
    div.moduletableagromap .ukrmapa #zhitomir {
      display: none;
      position: absolute;
      z-index: 9;
      margin: 0;
      padding: 0;
      left: 281px;
      top: 52px; }
    div.moduletableagromap .ukrmapa #vinnytsya {
      display: none;
      position: absolute;
      z-index: 9;
      margin: 0;
      padding: 0;
      left: 283px;
      top: 196px; }
    div.moduletableagromap .ukrmapa #kyiv {
      display: none;
      position: absolute;
      z-index: 9;
      margin: 0;
      padding: 0;
      left: 390px;
      top: 65px; }
    div.moduletableagromap .ukrmapa #cherkasy {
      display: none;
      position: absolute;
      z-index: 9;
      margin: 0;
      padding: 0;
      left: 401px;
      top: 173px; }
    div.moduletableagromap .ukrmapa #kirovograd {
      display: none;
      position: absolute;
      z-index: 9;
      margin: 0;
      padding: 0;
      left: 406px;
      top: 264px; }
    div.moduletableagromap .ukrmapa #nikolayev {
      display: none;
      position: absolute;
      z-index: 9;
      margin: 0;
      padding: 0;
      left: 431px;
      top: 333px; }
    div.moduletableagromap .ukrmapa #odessa {
      display: none;
      position: absolute;
      z-index: 9;
      margin: 0;
      padding: 0;
      left: 315px;
      top: 331px; }
    div.moduletableagromap .ukrmapa #krym {
      display: none;
      position: absolute;
      z-index: 9;
      margin: 0;
      padding: 0;
      right: 170px;
      top: 494px; }
    div.moduletableagromap .ukrmapa #kherson {
      display: none;
      position: absolute;
      z-index: 9;
      margin: 0;
      padding: 0;
      right: 250px;
      top: 385px; }
    div.moduletableagromap .ukrmapa #zapor {
      display: none;
      position: absolute;
      z-index: 9;
      margin: 0;
      padding: 0;
      right: 147px;
      top: 338px; }
    div.moduletableagromap .ukrmapa #dnipro {
      display: none;
      position: absolute;
      z-index: 9;
      margin: 0;
      padding: 0;
      right: 166px;
      top: 255px; }
    div.moduletableagromap .ukrmapa #donetsk {
      display: none;
      position: absolute;
      z-index: 9;
      margin: 0;
      padding: 0;
      right: 46px;
      top: 242px; }
    div.moduletableagromap .ukrmapa #poltava {
      display: none;
      position: absolute;
      z-index: 9;
      margin: 0;
      padding: 0;
      right: 249px;
      top: 146px; }
    div.moduletableagromap .ukrmapa #kharkiv {
      display: none;
      position: absolute;
      z-index: 9;
      margin: 0;
      padding: 0;
      right: 113px;
      top: 147px; }
    div.moduletableagromap .ukrmapa #chernigov {
      display: none;
      position: absolute;
      z-index: 9;
      margin: 0;
      padding: 0;
      left: 456px;
      top: 0px; }
    div.moduletableagromap .ukrmapa #sumy {
      display: none;
      position: absolute;
      z-index: 9;
      margin: 0;
      padding: 0;
      left: 577px;
      top: 1px; }
    div.moduletableagromap .ukrmapa #lugansk {
      display: none;
      position: absolute;
      z-index: 9;
      margin: 0;
      padding: 0;
      right: 0;
      top: 173px; }
    div.moduletableagromap .ukrmapa .point {
      position: absolute;
      z-index: 12;
      cursor: pointer;
      margin: 0;
      padding: 0;
      text-align: center; }
      div.moduletableagromap .ukrmapa .point div.leg1 {
        margin: 0 auto;
        padding: 0;
        width: 28px;
        height: 25px;
        overflow: hidden;
        cursor: pointer;
        background: url(/images/maps/legenda01.png) 0px 0px no-repeat; }
      div.moduletableagromap .ukrmapa .point div.leg1.hover {
        background: url(/images/maps/legenda01.png) 0px -25px no-repeat; }
      div.moduletableagromap .ukrmapa .point div.leg2 {
        margin: 0 auto;
        padding: 0;
        width: 28px;
        height: 25px;
        overflow: hidden;
        cursor: pointer;
        background: url(/images/maps/legenda02.png) 0px 0px no-repeat; }
      div.moduletableagromap .ukrmapa .point div.leg2.hover {
        background: url(/images/maps/legenda02.png) 0px -25px no-repeat; }
      div.moduletableagromap .ukrmapa .point p {
        margin: -5px 0px 0px 0px;
        padding: 0;
        font-size: 12px;
        color: #004990; }
      div.moduletableagromap .ukrmapa .point p.hover {
        color: #0067cc; }
    div.moduletableagromap .ukrmapa div.ocity {
      font-size: 12px;
      color: #000;
      position: absolute;
      z-index: 11;
      margin: 0;
      padding: 0; }
  div.moduletableagromap #kha {
    left: 720px;
    top: 190px; }
  div.moduletableagromap #don {
    left: 823px;
    top: 325px; }
  div.moduletableagromap #lug {
    left: 910px;
    top: 270px; }
  div.moduletableagromap #dni {
    left: 637px;
    top: 287px; }
  div.moduletableagromap #sim {
    left: 620px;
    top: 575px; }
  div.moduletableagromap #khe {
    left: 550px;
    top: 458px; }
  div.moduletableagromap #zap {
    left: 680px;
    top: 363px; }
  div.moduletableagromap #myk {
    left: 490px;
    top: 416px; }
  div.moduletableagromap #ode {
    left: 440px;
    top: 440px; }
  div.moduletableagromap #kir {
    left: 506px;
    top: 315px; }
  div.moduletableagromap #pol {
    left: 640px;
    top: 223px; }
  div.moduletableagromap #sum {
    left: 655px;
    top: 112px; }
  div.moduletableagromap #cgv {
    left: 475px;
    top: 73px; }
  div.moduletableagromap #kyi {
    left: 440px;
    top: 155px; }
  div.moduletableagromap #zhy {
    left: 330px;
    top: 145px; }
  div.moduletableagromap #riv {
    left: 225px;
    top: 113px; }
  div.moduletableagromap #lut {
    left: 162px;
    top: 117px; }
  div.moduletableagromap #ter {
    left: 162px;
    top: 213px; }
  div.moduletableagromap #lvi {
    left: 92px;
    top: 187px; }
  div.moduletableagromap #fra {
    left: 77px;
    top: 260px; }
  div.moduletableagromap #cnc {
    left: 175px;
    top: 314px; }
  div.moduletableagromap #uzh {
    left: 0px;
    top: 267px; }
  div.moduletableagromap #chapayeve {
    left: 670px;
    top: 180px; }
  div.moduletableagromap #vasyschyve {
    left: 750px;
    top: 160px; }
  div.moduletableagromap #lozova {
    left: 740px;
    top: 260px; }
  div.moduletableagromap #pokrovske {
    left: 725px;
    top: 325px; }
  div.moduletableagromap #troyany {
    left: 755px;
    top: 410px; }
  div.moduletableagromap #vesele {
    left: 665px;
    top: 405px; }
  div.moduletableagromap #snigurivka {
    left: 536px;
    top: 408px; }
  div.moduletableagromap #vilnogirsk {
    left: 596px;
    top: 320px; }
  div.moduletableagromap #dolynska {
    left: 514px;
    top: 340px; }
  div.moduletableagromap #novomyrgorod {
    left: 500px;
    top: 270px; }
  div.moduletableagromap #balta {
    left: 375px;
    top: 343px; }
  div.moduletableagromap #lyubashivka {
    left: 390px;
    top: 370px; }
  div.moduletableagromap #nizhyn {
    left: 515px;
    top: 90px; }
  div.moduletableagromap #cherni {
    left: 480px;
    top: 47px; }
  div.moduletableagromap #romny {
    left: 596px;
    top: 108px; }
  div.moduletableagromap #baryshivka {
    left: 475px;
    top: 148px; }
  div.moduletableagromap #pyryatyn {
    left: 553px;
    top: 170px; }
  div.moduletableagromap #vyly {
    left: 595px;
    top: 200px; }
  div.moduletableagromap #cherkassy {
    left: 500px;
    top: 218px; }
  div.moduletableagromap #zhashkiv {
    left: 400px;
    top: 243px; }
  div.moduletableagromap #fastiv {
    left: 410px;
    top: 186px; }
  div.moduletableagromap #andrushivka {
    left: 325px;
    top: 181px; }
  div.moduletableagromap #vinnytsya2 {
    left: 320px;
    top: 234px; }
  div.moduletableagromap #zhmerynka {
    left: 285px;
    top: 270px; }
  div.moduletableagromap #starokostyantyniv {
    left: 222px;
    top: 170px; }
  div.moduletableagromap #khmelnitsky2 {
    left: 220px;
    top: 215px; }
  div.moduletableagromap #torske {
    left: 180px;
    top: 260px; }
  div.moduletableagromap #kivertsi {
    left: 185px;
    top: 69px; }
  div.moduletableagromap #brody {
    left: 135px;
    top: 150px; }
  div.moduletableagromap div.adre {
    display: none;
    position: absolute;
    margin: 0;
    padding: 0;
    width: 310px;
    left: 0px;
    top: 410px;
    z-index: 13;
    background: #e5e5e5;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -khtml-border-radius: 5px;
    border-radius: 5px;
    behavior: url(/PIE.htc); }
  div.moduletableagromap div.adre.t1 {
    background: url(/images/t1.gif) 10px 10px no-repeat #e5e5e5; }
  div.moduletableagromap div.adre.t2 {
    background: url(../images/t2.gif) 10px 10px no-repeat #e5e5e5; }
  div.moduletableagromap div.adad {
    position: relative;
    margin: 0;
    padding: 10px 20px 10px 40px; }
  div.moduletableagromap #des1 {
    position: absolute;
    margin: 0;
    padding: 0px 0px 0px 30px;
    left: 800px;
    top: 470px;
    z-index: 13;
    background: url(../images/t1.gif) 0px 0px no-repeat; }
  div.moduletableagromap #des2 {
    position: absolute;
    margin: 0;
    padding: 0px 0px 0px 30px;
    left: 800px;
    top: 520px;
    z-index: 13;
    background: url(../images/t2.gif) 0px 0px no-repeat; }

html {
  position: relative;
  min-height: 100%; }

body {
  height: 100%;
  font-family: "Open Sans", Tahoma, Arial, Verdana, Georgia, "Times New Roman", Times, serif;
  font-size: 12px;
  color: #000;
  text-align: left;
  margin-bottom: 250px; }

header {
  margin: 20px 0; }

#scrollup {
  position: fixed;
  width: 45px;
  height: 45px;
  bottom: 50px;
  left: 10px;
  opacity: 0;
  text-align: center;
  font-size: 27px;
  padding: 0 3px 0 0;
  text-indent: 0;
  background: blue;
  border-radius: 100%;
  border: 5px solid green;
  color: white;
  transition: 0.3s;
  pointer-events: none; }
  #scrollup.active {
    display: block;
    opacity: 0.5;
    pointer-events: auto; }
    #scrollup.active:hover {
      opacity: 1; }

/* blog columns
------------------------ */
.beforebody {
  display: flex; }
  .beforebody > div {
    border-right: 1px solid #999; }
    .beforebody > div:nth-child(3) {
      border: none; }
    .beforebody > div .main-blog-col > h3 {
      color: #609240;
      font-size: 18px;
      padding: 0 0 10px;
      text-transform: uppercase;
      text-align: left;
      font-weight: normal; }
    .beforebody > div .main-blog-col ul {
      list-style-type: none;
      margin: 0;
      padding: 0; }
      .beforebody > div .main-blog-col ul li {
        border-bottom: 1px dotted #999;
        margin-bottom: 10px; }
    .beforebody > div .main-blog-col .data_content {
      color: #999;
      font-size: 10px; }
    .beforebody > div .main-blog-col h4.newsflash-title.main-blog-col {
      margin: 0;
      padding: 2px 0 5px 0; }
      .beforebody > div .main-blog-col h4.newsflash-title.main-blog-col a {
        color: #004990;
        text-decoration: none;
        font-size: 12px; }
    .beforebody > div .main-blog-col .newsflash-vert h1:not(.newsflash-title), .beforebody > div .main-blog-col .newsflash-vert h2:not(.newsflash-title), .beforebody > div .main-blog-col .newsflash-vert h3:not(.newsflash-title), .beforebody > div .main-blog-col .newsflash-vert h4:not(.newsflash-title), .beforebody > div .main-blog-col .newsflash-vert h5:not(.newsflash-title) {
      font-size: 12px !important;
      text-align: left !important;
      padding: 1px 0 !important;
      margin: 1px 0 !important;
      font-weight: normal !important;
      color: #000; }
